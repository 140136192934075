import React, { useEffect } from 'react';
import { CssBaseline, IconButton, Toolbar, Typography, FormControlLabel, Checkbox, InputAdornment, TextField, FormControl, ListItemIcon, Divider, Box, Tabs, Tab, Select, InputLabel, MenuItem, AppBar, Drawer, Container, ListItem, ListItemText, Button, Table, TableBody, TableRow, TableCell, TableHead, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import axios from 'axios';
import Keycloak from 'keycloak-js';
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import StorageIcon from '@material-ui/icons/StorageRounded';
import CodeIcon from '@material-ui/icons/Code';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Search from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { ApiScreen } from './ApiScreen';
import { ScooterDebugScreen } from './ScooterDebugScreen';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { UsersScreen } from './UsersScreen';
import { API_BASE_URL } from './config';


// if(window.location.hostname === "localhost") {
//   API_BASE_URL = "http://localhost:43101";
//   IAM_URL = "http://localhost:43105/auth";
// }

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL ?? "/auth",
  realm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'osm',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'osm-web',
  // redirectUri: window.location.origin,
});

// export const keycloak = Keycloak({
//   url: https://iam.rtb-bl.de/auth,
//   realm: 'rtb',
//   clientId: 'opensmartmobility-dev',
// });

// auto-inject / refresh auth token on api calls
axios.interceptors.request.use(async(config) => {
  console.log("axios interceptor, config:", config);
  console.log("keycloak instance:", keycloak);

  if(!config?.headers?.Authorization) {
    if(keycloak === undefined) {
      console.error("keycloak has not been loaded!")
    }
    else {
      try{
        await keycloak.updateToken(30)
        config.headers['Authorization'] = `Bearer ${keycloak.token}`;
      }
      catch(err) {
        console.error("updateToken err:", err);
      }
    }
  }

  return config;
});

function hexStr(num, len) {
  return num.toString(16).padStart(len, '0')
}

const deviceClasses = {
  elevator: {
    placeholderName: 'Elevator'
  },
  train: {
    placeholderName: 'Train',
    isStationary: false,
  }
}

function OSMTextField({...props}) {
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      {...props}
    />
  )
}

function aidtoIpv4String(aid) {
  const a0 = aid >> 24
  const a1 = (aid >> 16) & 0xFF
  const a2 = (aid >> 8) & 0xFF
  const a3 = (aid >> 0) & 0xFF
  return `${a0}.${a1}.${a2}.${a3}`
}

function ip4StringToAid(ip4) {
  const tokens = ip4.split('.')
  const a0 = parseInt(tokens[0])
  const a1 = parseInt(tokens[1])
  const a2 = parseInt(tokens[2])
  const a3 = parseInt(tokens[3])
  return (a0 << 24) & (a1 << 16) & (a2 << 8) & a3
}

function HexTextField({value, onValueChange, maxLen, ...props}) {
  const [strValue, setStrValue] = React.useState("")

  const onChange = (evt) => {
    let str = evt.target.value.replace(" ", "").replace(/[^A-Fa-f0-9]/g, "");
    // if(str.length === 0)
    //   str = "0"
    if(maxLen && str.length > maxLen)
      str = str.slice(0, maxLen)

    setStrValue(str)
  }

  const onBlur = (evt) => {
    console.log("HexTextField onBlur, evt:", evt, "str:", evt.target.value);
    if(evt.target.value.length === 0)
      onValueChange(0)
    else
      onValueChange(parseInt(evt.target.value, 16))
  }

  React.useEffect(() => {
    console.log("HexTextField useEffect, value:", value)
    if(value !== undefined) {
      // only update str int value differs
      // if(value !== parseInt(strValue, "hex")) {
        // console.log("value differs, old:", parseInt(strValue, "hex"), "new:", value);
        const str = value.toString(16).padStart(8, '0')
        setStrValue(str)
      // }
    }
  }, [value])

  return (
    <TextField
      {...props}
      value={strValue}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

function EntryDialogTabGeneral({entry, updateEntry, closeDialog}) {
  const [aidFormat, setAidFormat] = React.useState('hex')
  const [_manufacturerDeviceIdPrefix, setManufacturerDeviceIdPrefix] = React.useState(0x0300)

  const updateAid = (aidString, format) => {
    console.log("updateAid:", aidString, "format:", format);

    let val = null
    if(format === 'ipv4') {
      val = ip4StringToAid(aidString)
    }
    else if(format === 'hex') {
      val = parseInt(aidString, 16)
    }
    console.log("val:", val)

    updateEntry(old => { return {
      ...old,
      aid: val
    }})
  }

  const deleteEntry = async() => {
    try {
      const res = await axios.delete(`${API_BASE_URL}/api/v1/entries/${entry.id}`);
      console.log("delete res:", res)
      closeDialog()
    }
    catch(error) {
      alert("Error deleting entry: " + error.message)
    }
  }

  React.useEffect(() => {
    if(entry) {
      if(entry.aid) {
        const prefixLen = entry.aidPrefixLen ?? 24
        const prefix = entry.aid >> (24 - prefixLen)
        setManufacturerDeviceIdPrefix(prefix)
      }
    }
  }, [entry])

  // const renderDeviceIdInput = () => {
  //   if(aidFormat === 'hex') {
  //     return (
  //       <TextField
  //         label="Device ID"
  //         //value={entry.aid?.toString(16).padStart(8, '0')}
  //         defaultValue={entry.aid?.toString(16).padStart(8, '0')}
  //         onChange={evt => updateAid(evt.target.value, 'hex')}
  //         InputProps={{
  //           startAdornment: <InputAdornment position="start">0x</InputAdornment>,
  //         }}
  //         InputLabelProps={{ shrink: true }}
  //       />
  //     )
  //   }
  //   else {
  //     return (
  //       <TextField
  //         label="Device ID"
  //         //value={entry.aid?.toString(16).padStart(8, '0')}
  //         value={aidtoIpv4String(entry.aid ?? 0)}
  //         onChange={evt => updateAid(evt.target.value, 'ipv4')}
  //         InputLabelProps={{ shrink: true }}
  //       />
  //     )
  //   }
  // }

  return (<>

    <div style={{marginTop: 20}}>
      <TextField
        style={{minWidth: 600}}
        label="Internal Name (optional)"
        value={entry.internalName ?? ''}
        onChange={evt => updateEntry(old => ({...old, internalName: evt.target.value}))}
        helperText="Internal name of this entry (won't be shown to end users)"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>

    <Divider style={{marginTop: 30, marginBottom: 30}} />

    <h3>Addressing</h3>
    <div>
      <FormControl>
          <InputLabel id="entryType">Address type</InputLabel>
          <Select
            labelId="entryType"
            value={(entry?.aidPrefixLen === 32) ? "single" : "range"}
            // value={aidFormat ?? ''}
            onChange={evt => {
              if(evt.target.value === 'single') {
                updateEntry(old => ({...old, aidPrefixLen: 32}))
              }
              else {
                updateEntry(old => ({...old, aidPrefixLen: 16}))
              }
            }}
          >
            <MenuItem value={'single'}>Single Device</MenuItem>
            <MenuItem value={'range'}>Device range</MenuItem>
          </Select>
      </FormControl>
    </div>

    {/* <Divider style={{marginTop: 20, marginBottom: 20}} /> */}

    <div style={{marginTop: 20}}>

    <HexTextField
      label="Device ID (hex)"
      // type="string"
      value={entry.aid}
      onValueChange={val => updateEntry(old => ({...old, aid: val}))}
      maxLen={8}

      // value={entry.aid?.toString(16).padStart(8, '0')}
      // defaultValue={entry.aid?.toString(16).padStart(8, '0')}
      // onChange={evt => updateAid(evt.target.value, 'hex')}
      InputProps={{
        startAdornment: <InputAdornment position="start">0x</InputAdornment>,
      }}
      InputLabelProps={{ shrink: true }}
    />

    {/* {renderDeviceIdInput()} */}

    {/* <FormControl style={{marginLeft: 20}}>
        <InputLabel id="aidFormat">Format</InputLabel>
        <Select
          labelId="aidFormat"
          value={aidFormat ?? ''}
          onChange={evt => setAidFormat(evt.target.value)}
        >
          <MenuItem value={'hex'}>hex</MenuItem>
          <MenuItem value={'ipv4'}>IPv4</MenuItem>
        </Select>
    </FormControl> */}

    </div>
    <div style={{marginTop: 20, minWidth: 100}}>
      <TextField
        label="Prefix length"
        type="number"
        value={entry.aidPrefixLen ?? ''}
        onChange={evt => updateEntry(old => ({...old, aidPrefixLen: parseInt(evt.target.value)}))}
        placeholder={'24'}
        maxLen={4}
        InputProps={{ inputProps: { min: 0, max: 32 } }}
      />
    </div>

    <div style={{height: 30}}></div>
    <h3 style={{marginBottom: 20, marginTop: 30}}>Basic Information</h3>

    <div>
    <FormControl style={{minWidth: 200}} variant="outlined">
        <InputLabel id="device-class-label">Device Class</InputLabel>
        <Select
          labelId="device-class-label"
          // style={{ marginTop: 6 }}
          id="demo-simple-select"
          // value={synthVoiceName}
          // onChange={evt => setSynthVoiceName(evt.target.value)}
          value={entry.type ?? ''}
          onChange={evt => updateEntry(old => ({...old, type: evt.target.value}))}
          label="Device Class"
        >
          <MenuItem value={'train'}>Train</MenuItem>
          <MenuItem value={'bus'}>Bus</MenuItem>
          <MenuItem value={'station'}>Station</MenuItem>
          <MenuItem value={'elevator'}>Elevator</MenuItem>
          <MenuItem value={'trafficLight'}>Traffic light</MenuItem>
          <MenuItem value={'sign'}>Sign</MenuItem>
          <MenuItem value={'entry'}>Entry (/Building)</MenuItem>
          <MenuItem value={'roadworks'}>Roadworks / Construction Site</MenuItem>
          <MenuItem value={'escooter'}>E-Scooter</MenuItem>
          <MenuItem value={'museum'}>Museum</MenuItem>
          <MenuItem value={'poi'}>POI</MenuItem>
        </Select>
    </FormControl>
    </div>

    <div style={{marginTop: 30}}>
      <TextField
        style={{minWidth: 400}}
        label="Name"
        value={entry.name ?? ''}
        onChange={evt => updateEntry(old => ({...old, name: evt.target.value}))}
        helperText="A short descriptive name for this device / range of devices. Make sure to keep it short, as it will possibly be used in voice notifications."
        placeholder={deviceClasses[entry.type]?.placeholderName}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div style={{marginTop: 30}}>
      <TextField
        style={{minWidth: 700}}
        label="Short Description / Subtitle"
        value={entry.description ?? ''}
        variant="outlined"
        onChange={evt => updateEntry(old => ({...old, description: evt.target.value}))}
        InputLabelProps={{ shrink: true }}
      />
    </div>

    <h3 style={{marginTop: 30, display: 'none'}}>Information Text</h3>
    <div style={{marginTop: 30}}>
      <TextField
        style={{minWidth: 700}}
        label="Long Description"
        multiline
        rows={4}
        value={entry.contentText ?? ''}
        onChange={evt => updateEntry(old => ({...old, contentText: evt.target.value}))}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="Long descriptive text or additional information that will be displayed in a popup window when the user taps the device icon."
      />
    </div>

    <h3 style={{marginTop: 30, marginBottom: 30}}>Support</h3>
    <div style={{marginTop: 15}}>
      <TextField
        label="Operator Name"
        style={{minWidth: 500}}
        value={entry.operatorName ?? ''}
        onChange={evt => updateEntry(old => ({...old, operatorName: evt.target.value}))}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div style={{marginTop: 15}}>
      <TextField
        label="Support Email (public)"
        style={{minWidth: 500}}
        value={entry.supportEmail ?? ''}
        onChange={evt => updateEntry(old => ({...old, supportEmail: evt.target.value}))}
        type="email"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div style={{marginTop: 15}}>
      <TextField
        label="Support Website"
        style={{minWidth: 500}}
        value={entry.supportWebsite ?? ''}
        onChange={evt => updateEntry(old => ({...old, supportWebsite: evt.target.value}))}
        type="url"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div style={{marginTop: 15}}>
      <TextField
        label="Support Phone Number"
        style={{minWidth: 500}}
        value={entry.supportPhone ?? ''}
        onChange={evt => updateEntry(old => ({...old, supportPhone: evt.target.value}))}
        type="tel"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>

    <Divider style={{marginTop: 40, marginBottom: 40}} />

    <Button
      onClick={deleteEntry}
      >
      delete entry
    </Button>

    {/* https://play.google.com/store/apps/details?id=de.rtb_bl.rtbservice.blx */}
  </>)
}

function EntryDialogTabBeacon({entry, updateEntry}) {
  if(!entry) {
    return (<div>no entry</div>)
  }

  const beaconUUIDStr = 'B9407F30-F5F8-466E-AFF9-25556B57FE6D';

  const hciConfigCmd = () => {

    const uuidHexStr = beaconUUIDStr.replaceAll('-', '').split(/(?=(?:..)*$)/).join(" ");
    // " 63 6F 3F 8F 64 91 4B EE 95 F7 D8 CC 64 A8 63 B5"
    const majorHexStr = hexStr(entry.aid >>> 16, 4).split(/(?=(?:..)*$)/).join(" ");
    const minorHexStr = hexStr(entry.aid & 0x0000FFFF, 4).split(/(?=(?:..)*$)/).join(" ");

    return "sudo hcitool -i hci0 cmd 0x08 0x0008 1E 02 01 1A 1A FF 4C 00 02 15 "
      + uuidHexStr // uuid
      + " " + majorHexStr
      + " " + minorHexStr
      + " C8" // calib tx power
  }

  return (<>
    <h2>iBeacon</h2>

    <p style={{marginTop: 30}}>
    <pre>UUID: {beaconUUIDStr}</pre>

    {entry.aid !== undefined && (<>
      <pre>Major: {entry.aid >>> 16} (0x{hexStr(entry.aid >>> 16, 4)})</pre>
      <pre>Minor: {entry.aid & 0x0000FFFF} (0x{hexStr(entry.aid & 0x0000FFFF, 4)})</pre>

      {(entry.aidPrefixLen ?? 32) < 32 && (
        <p style={{opacity: 0.5}}>
          (Note: this entry matches multiple major/minor combinations.)
        </p>
      )}

    </>)}
    </p>

    <Divider style={{marginTop: 30, marginBottom: 30}} />

    <h3>Testing</h3>

    <p style={{marginTop: 30, display: "none"}}>
      <a href={"locid:openDevice?aid=" + entry?.aid}>TEST: open in LOC.id</a>
    </p>

    <p style={{marginTop: 30}}>
      For testing, you may want to use some iBeacon simulator app, e.g.:
      <ul>
        <li><a href='https://play.google.com/store/apps/details?id=net.alea.beaconsimulator' target="_blank" rel="noreferrer">Beacon Simulator (Android)</a></li>
        <li><a href='https://apps.apple.com/de/app/locate-beacon/id738709014' target="_blank" rel="noreferrer">Locate Beacon (iOS)</a></li>
      </ul>
    </p>

    <h3>Linux / Bluez / Raspberry Pi</h3>

    <SyntaxHighlighter language="bash" showLineNumbers={false}>
        { `
#!/bin/bash

# sudo apt install -y bluez
sudo hciconfig hci0 up
sudo hciconfig hci0 leadv 3
sudo hciconfig hci0 noscan
        `.trim()
        + "\n" + hciConfigCmd() }
    </SyntaxHighlighter>

  </>)
}

function EntryDialogTabIntegrations({entry, updateEntry}) {

  const handleCheckboxChange = (evt, key) => {
    console.log("handleChange:", evt.target.checked)
    updateEntry(old => {
      return {
        ...old,
        [key]: evt.target.checked
      }
    })
  }

  return (<>
    <h2>Integrations (todo)</h2>

    <div>
      <FormControlLabel
          control={
            <Checkbox
              checked={entry.osmSync ?? false}
              onChange={evt => handleCheckboxChange(evt, 'osmSync')}
              name="checkedB"
              color="primary"
            />
          }
          label="Synchronize to OpenStreetMap"
        />
    </div>

    <div>
      <FormControlLabel
          control={
            <Checkbox
              checked={entry.wheelmapSync ?? false}
              onChange={evt => handleCheckboxChange(evt, 'wheelmapSync')}
              name="checkedB"
              color="primary"
            />
          }
          label="Synchronize to wheelmap.org"
        />
    </div>

  </>)
}

function nullIfEmpty(val) {
  if((val?.length ?? 0) === 0) {
    return null;
  }
  else {
    return val;
  }
}

function EntryDialogTabNotifications({entry, updateEntry}) {

  const handleCheckboxChange = (evt, key) => {
    console.log("handleChange:", evt.target.checked)
    updateEntry(old => {
      return {
        ...old,
        [key]: evt.target.checked
      }
    })
  }

  return (<>
    <h2>Notifications</h2>

    <div>
      <FormControlLabel
          control={
            <Checkbox
              checked={entry.notifyOnEnter ?? false}
              onChange={evt => handleCheckboxChange(evt, 'notifyOnEnter')}
              name="checkedB"
              color="primary"
            />
          }
          label="Notify on region entry"
        />
    </div>

    <div style={{marginTop: 15}}>
      <TextField
        label="Notification Title"
        style={{minWidth: 500}}
        value={entry.notTitle ?? ''}
        onChange={evt => updateEntry(old => ({...old, notTitle: nullIfEmpty(evt.target.value)}))}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
    <div style={{marginTop: 30}}>
      <TextField
        style={{minWidth: 700}}
        label="Body"
        multiline
        minRows={4}
        value={entry.notBody ?? ''}
        onChange={evt => updateEntry(old => ({...old, notBody: nullIfEmpty(evt.target.value)}))}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="Long descriptive text or additional information that will be displayed in a popup window when the user opens the notification."
      />
    </div>
    <div style={{marginTop: 15}}>
      <TextField
        label="Action URL"
        style={{minWidth: 500}}
        value={entry.notURL ?? ''}
        onChange={evt => updateEntry(old => ({...old, notURL: nullIfEmpty(evt.target.value)}))}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  </>)
}

function EntryDialogTabApps({entry, updateEntry}) {

  const onAddAppActionClicked = async() => {
    updateEntry(old => {
      let e = {...old}
      if(!e.appActions)
        e.appActions = []

      let newAction = {}
      if(e.appActions.length === 0) {
        newAction['name'] = '__launch__'
      }
      e.appActions.push(newAction)
      return e
    })
  }

  const onAppActionDeleteClicked = (index) => {
    updateEntry(old => {
      let e = {...old}
      e.appActions.splice(index, 1);
      return e
    })
  }

  const appActionUpdate = (index, data) => {
    updateEntry(old => {
      let e = {...old}
      e.appActions[index] = { ...e.appActions[index], ...data }
      return e
    })
  }

  const onAppStoreURLChange = async(evt) => {
    console.log("onAppStoreURLChange")

    const text = evt.target.value;

    updateEntry(old => ({...old,
      iosStoreURL: text
      }));

    const match = text.match(/apps.apple.*\/id(\d*)/)
    if(match) {
      const itmsId = match[1];
      updateEntry(old => ({...old,
        iosItmsId: itmsId
        }));

      // scrape apple store page
    //   const res = await axios.post(`${API_BASE_URL}/api/v1/internal/app_store_scrape`, {
    //     itms: itmsId
    //   });
    //   console.log("get entry res:", res)

    //   if(res.data.description) {
    //     updateEntry(old => ({...old, appDescription: res.data.description}));
    //   }
    //   if(res.data.name) {
    //     updateEntry(old => ({...old, appName: res.data.name}));
    //   }
    }
  }

  return (<>
    <h3>iOS App</h3>

    <div style={{marginTop: 30}}>
      <OSMTextField
        style={{minWidth: 800}}
        label="Apple App Store URL"
        value={entry.iosStoreURL ?? ''}
        onChange={onAppStoreURLChange}
        placeholder="https://apps.apple.com/us/app/rtb-loc-id/id1146809656"
        helperText={(
          <div>
            Apple App Store URL, e.g. https://apps.apple.com/us/app/rtb-loc-id/id1146809656. <br/>
            (The URL can be found in in <a href="https://appstoreconnect.apple.com/">App Store Connect</a> -&gt; Your App -&gt; App Information -&gt; Show in App Store.)
          </div>
          )}
        helperText2={() => {
          return "Apple-Store web URL, e.g. https://apps.apple.com/us/app/rtb-loc-id/id1146809656. You can find the store link in App Store Connect -> Your App -> App Information -> Show in App Store";
        }}
      />
    </div>
    <div style={{marginTop: 30}}>
      <OSMTextField
        style={{minWidth: 300}}
        label="ITMS ID"
        value={entry.iosItmsId ?? ''}
        onChange={evt => updateEntry(old => ({...old, iosItmsId: evt.target.value}))}
      />
    </div>

    <div style={{marginTop: 30}}>
      <OSMTextField
        style={{minWidth: 800}}
        label="App Name"
        value={entry.appName ?? ''}
        onChange={evt => updateEntry(old => ({...old, appName: evt.target.value}))}
      />
    </div>

    <div style={{marginTop: 30}}>
      <OSMTextField
        style={{minWidth: 800}}
        label="App Description"
        value={entry.appDescription ?? ''}
        multiline
        rows={4}
        onChange={evt => updateEntry(old => ({...old, appDescription: evt.target.value}))}
      />
    </div>
    <div style={{marginTop: 30}}>
      <OSMTextField
        style={{minWidth: 800}}
        label="Icon URL"
        value={entry.appIconURL ?? ''}
        onChange={evt => updateEntry(old => ({...old, appIconURL: evt.target.value}))}
      />
    </div>

    <Divider style={{marginTop: 30, marginBottom: 30}} />

    <h3 style={{marginTop: 30}}>App Actions</h3>

    <p>
      App Actions allow you to define simple shortcuts to your app that users can use to quickly interact with your device.
    </p>

    <p>
      (To implement URL scheme support for iOS apps, see <a target="_blank" href="https://developer.apple.com/documentation/xcode/defining-a-custom-url-scheme-for-your-app" rel="noreferrer">Apple Developer: Custom URL Schemes</a>.)
    </p>

    {(entry.appActions ?? []).map((action, index) => {
      return (<>
        <Box
          p={1} m={1} border={1} borderRadius={5}
          style={{backgroundColor: 'rgb(245, 245, 245)', borderColor: 'lightgrey', marginBottom: 20, paddingLeft: 20, paddingTop: 15}}>

          {action.name === '__launch__' && (
            <TextField
              style={{minWidth: 200, marginRight: 20}}
              label="Action name"
              defaultValue="Launch App"
              readonly
              InputProps={{
                readOnly: true
              }}
            />
          )}
          {action.name !== '__launch__' && (
            <TextField
              style={{minWidth: 200, marginRight: 20}}
              label="Action name"
              value={action.name}
              onChange={evt => appActionUpdate(index, {name: evt.target.value})}
              InputLabelProps={{ shrink: true }}
            />
          )}
          <div>
            <TextField
              style={{minWidth: 400, marginTop: 20}}
              label="URL"
              placeholder="your-app-scheme://"
              value={action.url}
              onChange={evt => appActionUpdate(index, {url: evt.target.value})}
            />
          </div>

          <h4>URL Parameters</h4>
          <p>todo</p>

          {/* {action.name !== '__launch__' && ( */}
            <Button
              onClick={() => onAppActionDeleteClicked(index)}
              color="warning"
            >delete</Button>
          {/* )} */}

        </Box>
      </>)
    })}

    <div>
        <Button
          onClick={onAddAppActionClicked}
          color="primary"
        >add</Button>
    </div>

  </>);
}

function EntryDialogTabGeo({entry, updateEntry}) {
  const mapRef = React.useRef();
  const [mapCenter, setMapCenter] = React.useState([49.00661756674637, 8.388640874756774]);

  async function persistChanges() {
    console.log("persistChanges")
    const map = mapRef.current;

    var fg = L.featureGroup();
    map.eachLayer((layer)=>{
      if(layer instanceof L.Path || layer instanceof L.Marker) {
        console.log("adding geojson layer:", layer);
        fg.addLayer(layer);
      }
    });

    // console.log("getGeomanDrawLayers", map.pm.getGeomanDrawLayers());
    // for(let layer of map.pm.getGeomanDrawLayers()) {
    //   if(layer instanceof L.Path || layer instanceof L.Marker) {
    //     console.log("adding geojson layer:", layer);
    //     fg.addLayer(layer);
    //   }
    // }

    // var fg = L.featureGroup();
    // var layers = L.PM.Utils.findLayers(map);
    // for(let layer of map.pm.getGeomanDrawLayers()) {
    //   if(layer instanceof L.Path || layer instanceof L.Marker) {
    //     console.log("adding geojson layer:", layer);
    //     fg.addLayer(layer);
    //   }
    // }

    const geoJson = fg.toGeoJSON();
    console.log("geoJson:", geoJson);

    console.log("str:", JSON.stringify(geoJson))

    updateEntry(old => ({...old, geo: geoJson}));
  }

  const load = async() => {
    const map = mapRef.current;
    console.log("map:", map)
    if(!map) {
      // alert("mapRef nil!")
      return
    }

    // see https://github.com/geoman-io/leaflet-geoman
    map.pm.addControls({
      position: 'topleft',
      drawPolyline: false,
      drawRectangle: false,
      drawCircle: true,
      drawCircleMarker: false,
    });

    map.on("pm.drawstart", (e) => {
      console.log("drawStart", e)
    });
    map.on("pm.drawend", (e) => {
      console.log("drawEnd", e)
      persistChanges()
    });
    map.on("pm.create", (e) => {
      console.log("pm.create evt", e)
    });
    map.on("layeradd", (e) => {
      console.log("layeradd evt", e)
      if(e.layer._drawnByGeoman === true) {
        console.log("drawn by user!")
        persistChanges();
      }
    });
    map.on('pm:globaleditmodetoggled', (e) => {
      console.log("'pm:globaleditmodetoggled'", e);
      persistChanges()
    });

    // disable Draw Mode
    // map.pm.disableDraw();

    // load geojson

    loadEntryGeoJson();
  }

  const loadEntryGeoJson = async() => {
    console.log("loadEntryGeoJson");

    if(mapRef.current && entry?.geo) {
      const map = mapRef.current;
      const res = L.geoJSON(entry.geo).addTo(map);
      console.log("add geojson res:", res);
    }
  }

  React.useEffect(() => {
    console.log("map/entry useEffect");

    if(mapRef.current && entry?.geo) {
      loadEntryGeoJson();
    }
  }, [mapRef, entry])

  return (<>
    <MapContainer
        center={mapCenter}
        // height={600}
        // width={900}
        zoom={6}
        scrollWheelZoom={true}
        style={{ height: 600, width: '100%', marginTop: 20 }}
        whenCreated={mapInstance => {
          console.log("mapcontainer whenCreated, mapInstance:", mapInstance);
          mapRef.current = mapInstance;
          load()
        }}
        whenReady={mapInstance => {
          console.log("whenReady:", mapInstance);
          // load();
        }}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>

    <pre>
      geojson: {entry?.geo ? JSON.stringify(entry.geo, null, 4) : "null"}
    </pre>


  </>)
}

function EntryDialogTabRaw({entry, updateEntry}) {

  const onEditorChanged = async(json) => {
    console.log("onEditorChanged:", onEditorChanged);
    updateEntry(json);
  }

  return (<>
    <JsonEditor
      value={entry}
      onChange={onEditorChanged}
      theme="ace/theme/github"
    />

    <pre>
      {JSON.stringify(entry, null, 4)}
    </pre>
  </>);
}

function AssistiveDbEntryEditDialog({entryId, onClose}) {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [entry, setEntry] = React.useState({})
  const {auth, setAuth} = React.useContext(AuthContext)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const load = async() => {
    try {
      const res = await axios.get(`${API_BASE_URL}/api/v1/entries/${entryId}`, {
        responseType: 'json',
      });
      console.log("get entry res:", res)
      setEntry(res.data)
    }
    catch(error) {
      alert("Error fetching entry: " + error.message)
      setEntry({})
      handleDialogCloseClick()
    }
  }

  const save = async() => {
    // todo: validate
    if(entry.aid === undefined) {
      throw new Error("missing aid")
    }

    if(entryId) {
      const res = await axios.post(`${API_BASE_URL}/api/v1/entries/${entryId}`, entry);
      console.log("create entry res:", res)
    }
    else {
      const res = await axios.post(`${API_BASE_URL}/api/v1/entries`, entry);
      console.log("create entry res:", res)
    }
  }

  useEffect(() => {
    console.log("entryId useEffect, auth:", auth)

    // new entry
    if(entryId === 0) {
      let entryDefaults = {
        appActions: [
          { "name": "__launch__" }
        ],
        aidPrefixLen: 32,
      }

      // propose aid (from authorization)
      const aidRanges = auth?.entryPermissions?.aidRanges;
      if(aidRanges?.length === 1) {
        const range = aidRanges[0]
        entryDefaults.aid = range.aid;
        entryDefaults.aidPrefixLen = range.aidPrefixLen
      }

      setEntry(entryDefaults)

      setDialogOpen(true)
    }
    else if(entryId) {
      setDialogOpen(true)
      load()
    }
    else {
      setDialogOpen(false)
    }
  }, [entryId])

  const handleDialogCloseClick = () => {
    setDialogOpen(false)
    setTabValue(0)
    onClose()
  }

  const handleDialogSaveClick = async() => {
    try {
      await save()
      setDialogOpen(false)
      onClose()
    }
    catch(error) {
      alert("Error saving entry: " + error.message)
    }
  }

  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    console.log("handleTabChange:", newValue)
    setTabValue(newValue)
  }

  return (<>
    <Dialog
      open={dialogOpen}
      onClose={(_, reason) => {
        if(reason === "escapeKeyDown")
          handleDialogCloseClick()
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={isSmallScreen}
      >
        <DialogTitle id="form-dialog-title">
          {(entryId === 0)
            ? ("New Entry")
            : ("Edit " + entry.name ?? entry.internalName ?? "")}
        </DialogTitle>
        <DialogContent dividers>

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            // style={{backgroundColor: 'rgb(25, 118, 210)'}}
            indicatorColor="primary"
            // variant="fullWidth"
            >
              {/*  icon={<InfoIcon />} iconPosition="start"  */}
              <Tab label="General"/>
              <Tab label="App links" />
              <Tab label="Beacon" />
              <Tab label="Geo" />
              <Tab label="Notifications" />
              <Tab label="Integrations" />
              <Tab label="Raw" />
          </Tabs>

          {tabValue === 0 && (
            <Box p={3}>
              <EntryDialogTabGeneral entry={entry} updateEntry={setEntry} closeDialog={handleDialogCloseClick} />
            </Box>
          )}
          {tabValue === 1 && (
            <Box p={3}>
              <EntryDialogTabApps entry={entry} updateEntry={setEntry} />
            </Box>
          )}
          {tabValue === 2 && (
            <Box p={3}>
              <EntryDialogTabBeacon entry={entry} updateEntry={setEntry} />
            </Box>
          )}
          {tabValue === 3 && (
            <EntryDialogTabGeo entry={entry} updateEntry={setEntry} />
          )}
          {tabValue === 4 && (
            <EntryDialogTabNotifications entry={entry} updateEntry={setEntry} />
          )}
          {tabValue === 5 && (
            <Box p={3}>
              <EntryDialogTabIntegrations entry={entry} updateEntry={setEntry} />
            </Box>
          )}
          {tabValue === 6 && (
            <EntryDialogTabRaw entry={entry} updateEntry={setEntry} />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleDialogCloseClick}
            color="grey">
            Cancel
          </Button>
          <Button
            onClick={handleDialogSaveClick}
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </>)
}

function DebugScreen({auth}) {

  return (<>
    <h1>Debug</h1>

    <h2>access token</h2>
    <pre>
      {JSON.stringify(auth.tokenParsed, null, 4)}
    </pre>

    <h2>auth</h2>
    <pre>
      auth:
      {JSON.stringify(auth, null, 4)}
    </pre>

    <h2>id token</h2>
    <pre>
      {JSON.stringify(auth.tokenParsed, null, 4)}
    </pre>
  </>);
}

function DevicesScreen({auth}) {
  const [entries, setEntries] = React.useState([])
  const [editEntryId, setEditEntryId] = React.useState(null)
  const [searchString, setSearchString] = React.useState("")

  const loadEntries = () => {
    const f = async() => {
      try {
        let params = {
          onlyOwned: true
        }
        if(searchString?.length > 0) {
          params['query'] = searchString
        }

        const res = await axios.get(`${API_BASE_URL}/api/v1/entries`, {
          responseType: 'json',
          params: params
        });
        let ent = res.data.sort((a,b) => {
          const aAidHex = (a.aid ?? 0).toString(16);
          const bAidHex = (b.aid ?? 0).toString(16);
          return aAidHex.localeCompare(bAidHex);
        });
        console.log("res:", res)
        setEntries(ent)
      }
      catch(error) {
        alert(error.message)
      }
    }
    f();
  }

  React.useEffect(() => {
    loadEntries();
    return () => {}
  }, []);

  const onNewEntryClick = async() => {
    setEditEntryId(0)
  }
  const onEditClicked = async(entryId) => {
    setEditEntryId(entryId)
  }

  const renderAid = (entry) => {
    if(entry.aid === undefined)
      return <span>no aid</span>;

    const prefixLen = entry.aidPrefixLen ?? 32;
    const netDigits = (prefixLen) / 4;
    const isNet = prefixLen < 32;

    const aidStr = entry.aid?.toString(16).toUpperCase().padStart(8, '0');
    const aidNetStr = aidStr.slice(0, netDigits);
    const aidRemainderStr = aidStr.slice(netDigits, 8);

    const marginLeft = isNet ? 0 : 10;

    return (<>
      <span style={{color: 'grey', marginLeft}}>
        0x
      </span>
      {isNet && (<>
        <span style={{fontWeight: 'bold'}}>
          {aidNetStr}
        </span>
        <span>
          {aidRemainderStr}
        </span>
      </>)}

      {!isNet && (<>
        <span>
          {aidNetStr}
        </span>
        <span>
          {aidRemainderStr}
        </span>
      </>)}


      {(entry.aidPrefixLen !== 32 && entry.aidPrefixLen !== undefined) && (<>
        <span></span>
        <span style={{color: 'grey'}}>/{entry.aidPrefixLen}</span>
      </>)}
    </>);
  }

  const onEditDialogClose = async() => {
    setEditEntryId(null)
    loadEntries()
  }

  function aidMatches(aid, netAid, netPrefixLen) {
      const mask = 0xFFFFFFFF << (32 - netPrefixLen);
      return ((aid & mask) === netAid);
  }

  const hasWritePermission = (dbEntry) => {
    const entryAid = dbEntry.aid;
    if(!entryAid)
      return false;

    for(let perm of (auth?.entryPermissions?.aidRanges ?? [])) {
      if(aidMatches(entryAid, perm.aid, perm.aidPrefixLen ?? 32)) {
        return true;
      }
    }

    return false;
  }

  React.useEffect(() => {
    loadEntries();
  }, [searchString]);

  const searchValueTimerRef = React.useRef(null)
  const onSearchValueChange = (evt) => {
    clearTimeout(searchValueTimerRef.current)
    searchValueTimerRef.current = setTimeout(() => {
      setSearchString(evt.target.value)
    }, 500);
  }

  return (<>
    <AssistiveDbEntryEditDialog
      open={true}
      entryId={editEntryId}
      onClose={onEditDialogClose}
      />

    <h1>Device Database</h1>

    <Box>
      <div>
        <TextField id="outlined-search"
          style={{width: '100%', marginBottom: 30, marginTop: 20}}
          // label="Search"
          type="search"
          placeholder="Search ..."
          onChange={onSearchValueChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          />
      </div>
    </Box>

    {(entries && entries.length > 0) ? (
      <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Device ID/Range</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Class</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {entries.map(e => {
          return (
            <TableRow key={e.aid}>
              <TableCell>
                {renderAid(e)}
              </TableCell>
              <TableCell>
                { (e.internalName ?? '').length > 0 ? e.internalName : e.name }
              </TableCell>
              <TableCell>
                {e.type}
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  onClick={() => onEditClicked(e.id)}
              >
                  {hasWritePermission(e) ? "edit" : "view"}
              </Button>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      </Table>
    ) : (
      <div>no results</div>
    )}



    <Button
      onClick={onNewEntryClick}
      color="primary"
      style={{marginTop: 20}}
      startIcon={<AddIcon />}
      >Add Entry</Button>

    { false && (<>
      <Divider style={{marginTop: 100}} />

      {entries.map(e => {
        return (
          <pre>{JSON.stringify(e, null, 4)}</pre>
        )
      })}
    </>)}
  </>)
}

function Main() {
  return (
    <div></div>
  )
}

const AuthContext = React.createContext(null);

export default function App() {
  const drawerWidth = 200

  const [auth, setAuth] = React.useState(null)

  const initKeycloak = async() => {
    console.log("initKeycloak");

    const initRes = await keycloak.init({ onLoad: 'login-required'});
    console.log("initRes:", initRes);

    if(initRes === true) {
      window.keycloak = keycloak;
      console.log("id token:", keycloak.idTokenParsed)
      console.log("access token:", keycloak.tokenParsed)

      setAuth({
        tokenParsed: keycloak.tokenParsed,
        idTokenParsed: keycloak.idTokenParsed
      })

      setInterval(() => {
        console.log("updating oidc token...")
        keycloak.updateToken(60)
        // setAuth(old => {
        //   ...old,
        //   tokenParsed: keycloak.tokenParsed
        // })
      }, 60000)
    }
    else {
      console.error("keycloak init result error")
    }
  }

  const fetchEntryPermissions = async() => {
    const res = await axios.get(`${API_BASE_URL}/api/v1/entryPermissions`, {
      responseType: 'json'
    });
    console.log("entryPermissions res:", res);

    setAuth(old => {
      return {
        ...old,
        entryPermissions: res.data
      }
    });
  }

  const signOut = () => {
    keycloak.logout()
  }

  React.useEffect(() => {
    async function load() {
      try {
        await initKeycloak()
      }
      catch(err) {
        console.error(err);
        alert("Error initializing oidc: " + err.message)
      }

      try {
        await fetchEntryPermissions();
      }
      catch(err) {
        console.error(err);
        alert("Error fetching entry permissions: " + err.message)
      }
    }

    load();

    return () => {}
  }, []);

  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onDrawerButtonClicked = async() => {
    setDrawerOpen(old => !old)
  }

  // close drawer when switching from large to small screen
  React.useEffect(() => {
    if(isSmallScreen)
      setDrawerOpen(false)
  }, [isSmallScreen])

  if(auth === null) {
    return (<div>No auth, redirecting ...</div>);
  }

  return (<>
    <div style={{flex: 1}}>
    <AuthContext.Provider value={{auth, setAuth}}>
    <BrowserRouter>

    <div style={{display: 'flex'}}>

    <CssBaseline />

    <AppBar
      position="fixed"
      style={{zIndex: 20}}
      // elevation={10}
      >
      <Toolbar
        variant="dense"
        // style={{backgroundColor: '#e6e5e5'}}
        style={{backgroundColor: 'white'}}
        >

        {isSmallScreen && (
            <IconButton
              color="white"
              aria-label="open drawer"
              onClick={onDrawerButtonClicked}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}

        <Typography component="h1" variant="h6" color="inherit" noWrap
          style={{color: 'black', fontWeight: 400, paddingLeft: 0, flexGrow: 1}}
          >
          OpenSmartMobility / LOC.id
        </Typography>

        {!isSmallScreen && (
          <Typography style={{color: "#5e6a6f"}} noWrap>
            {auth?.idTokenParsed?.name}
          </Typography>
        )}

        <IconButton
          onClick={signOut}
          >
          <ExitToAppIcon />
        </IconButton>

      </Toolbar>
    </AppBar>

    <Drawer
      variant={isSmallScreen ? "temporary" : "permanent"}
      open={drawerOpen}
      style={{width: drawerWidth, zIndex: 1, flexShrink: 0}}
      PaperProps={{style: {width: drawerWidth, zIndex: 1}}}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onEscapeKeyDown={() => setDrawerOpen(false)}
      onBackdropClick={() => setDrawerOpen(false)}
      >
        <Toolbar />

        <div style={{height: 20}}></div>

        <ListItem button component={NavLink} to="/db" activeClassName="Mui-selected">
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button component={NavLink} to="/apidoc" activeClassName="Mui-selected">
          <ListItemIcon>
            <CodeIcon />
          </ListItemIcon>
          <ListItemText primary="API" />
        </ListItem>

        { auth?.tokenParsed?.realm_access.roles.includes("rtb.user_admin") && (<>
          <ListItem button component={NavLink} to="/users" activeClassName="Mui-selected">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>

          <ListItem button component={NavLink} to="/scooter-debug" activeClassName="Mui-selected">
            <ListItemText primary="Scooter-Debug" />
          </ListItem>
        </>)}

    </Drawer>

    <main style={{flexGrow: 1, height: '100vh', overflow: 'auto'}}>
      <Toolbar />
      <Container maxWidth={false}>
          <Route path="/">
            <Main />
          </Route>

          <Route path="/db">
            <DevicesScreen auth={auth} />
          </Route>

          <Route path="/apidoc">
            <ApiScreen auth={auth} />
          </Route>

          <Route path="/debug">
            <DebugScreen auth={auth} />
          </Route>

          <Route path="/scooter-debug">
            <ScooterDebugScreen auth={auth} />
          </Route>

          <Route path="/users">
            <UsersScreen auth={auth} />
          </Route>

          {false && (<>
            <Divider />
            <pre>
              auth.tokenParsed:
              {JSON.stringify(auth.tokenParsed, null, 4)}
            </pre>
            <pre>
              AUTH:
              {JSON.stringify(auth, null, 4)}
            </pre>
          </>)}

          <div style={{height: "50px"}}></div>

      </Container>
    </main>

    </div>
    </BrowserRouter>
    </AuthContext.Provider>
    </div>
  </>);
}
