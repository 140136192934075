import React from 'react';
import { Typography, Table, TableBody, TableRow, TableCell, CircularProgress } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import axios from 'axios';
import { API_BASE_URL } from './config';

export function ApiScreen({auth}) {
  const oidcUsername = auth?.idTokenParsed?.email ?? "YOUR_EMAIL";

  const [apiVars, setApiVars] = React.useState(null)

  React.useEffect(() => {
    async function load() {
      const res = await axios.get(`${API_BASE_URL}/api/v1/apiAccessVars`, {
        responseType: 'json'
      });
      console.log("get api vars res:", res)
      setApiVars(res.data)
    }

    load();
  }, []);

  if(apiVars === null) {
    return (<CircularProgress />);
  }

  return (<>
    <h1>API</h1>

    <div style={{display: "none"}}>
    <a href="locid://test">locid uri test</a>
    <a href="locid:openDevice?aid=1234">locid:openDevice</a>
    </div>

    <h2>Authorization</h2>

    The OpenSmartMobility APIs uses the <a href="https://tools.ietf.org/html/rfc6749" target="_blank" rel="noreferrer">OAuth 2.0 protocol</a> for authentication and authorization.

    <h3>Requesting an access token</h3>

    <SyntaxHighlighter language="bash" showLineNumbers={false}>
        { `
#!/bin/bash

ACCESS_TOKEN=$(curl -s \\
    -d "client_id=${apiVars.oidc_client_id}" \\
    -d "client_secret=${apiVars.oidc_client_secret}" \\
    -d "grant_type=password" \\
    -d "username=${oidcUsername}" \\
    -d "password=YOUR_PASSWORD" \\
    "${apiVars.oidc_base_url}/auth/realms/osm/protocol/openid-connect/token" | jq -r ".access_token")

echo "access token: $ACCESS_TOKEN"
        `.trim() }
    </SyntaxHighlighter>

    <h2 style={{marginTop: 50}}>Objects</h2>
    <h3>Entry</h3>

    <p>
      Represents an entity in the database. Entries are loosely typed json objects that represent things in the real world.
    </p>
    <p>
      Keys can be dynamically added ("tagged"), the interpretation is up to client applications.
    </p>

    <h3>Example</h3>
    <SyntaxHighlighter language="javascript" showLineNumbers={false}>
        { `
    {
      aid: 50790404,
      type: "elevator"
      name: "Second Floor",
      description: "Shopping Center Downing Street"
    }
        ` }
    </SyntaxHighlighter>

    <h3>Common Keys / Tags</h3>
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell><strong>id</strong></TableCell>
          <TableCell>Database ID of this entry.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>createdAt</strong> (read-only)</TableCell>
          <TableCell>Timestamp (in ms since spoch) of entry creation.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>updatedAt</strong> (read-only)</TableCell>
          <TableCell>Timestamp (in ms since spoch) of last entry update.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>aid</strong></TableCell>
          <TableCell>Unique / persistent ID that is used to match this entry against devices (e.g. Bluetooth Beacons)</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>internalName</strong></TableCell>
          <TableCell>Internal name of this entry (Not shown to end users).</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>type</strong></TableCell>
          <TableCell>Type of this device, e.g. "station", "bus", "elevator", "trafficLight"</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>name</strong></TableCell>
          <TableCell>Primary name of this device in the real world.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>description</strong></TableCell>
          <TableCell>Can be used to provide additional information about this entry. Text should be kept short (a few words)</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>contentText</strong></TableCell>
          <TableCell>Additional detailed information about this entry. Will be displayed e.g. in "detail views" of apps.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>appLinks</strong></TableCell>
          <TableCell>Used to link to custom apps used to control this object. (work in progress)</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <h2 style={{marginTop: 50}}>Methods</h2>

    <h3>GET /api/v1/entries</h3>
    <Typography variant="body1">
      Gets a list of database entries.
    </Typography>

    <h3>Parameters</h3>
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell><strong>deviceClass</strong> (optional)</TableCell>
          <TableCell>Device class filter</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>name</strong> (optional)</TableCell>
          <TableCell>Device name filter</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>limit</strong> (optional)</TableCell>
          <TableCell>Maximum number of results to return</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><strong>skip</strong> (optional)</TableCell>
          <TableCell>Number of entries to omit (from the start of the result set)</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <h3>Example</h3>
    <SyntaxHighlighter language="bash" showLineNumbers={false}>
        { `
#!/bin/bash

curl \\
    -H "Authorization: Bearer $ACCESS_TOKEN" \\
    -H "Accept: application/json" \\
    "https://opensmartmobility.org/api/v1/entries?limit=10"
        `.trim() }
    </SyntaxHighlighter>

    <h3 style={{ marginTop: 50 }}>GET /api/v1/entries/:id</h3>

    <Typography variant="body1">
      Gets a single database entry.
    </Typography>

    <h4>Parameters</h4>
    <Table size="small" dense>
      <TableBody>
        <TableRow>
          <TableCell><strong>id</strong></TableCell>
          <TableCell>Entry ID</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <h3 style={{ marginTop: 20 }}>Response</h3>
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell><strong>object</strong></TableCell>
          <TableCell>Entry</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <h3 style={{ marginTop: 50 }}>POST /api/v1/entries</h3>

    <Typography variant="body1">
      Creates a single database entry
    </Typography>

    <h3 style={{ marginTop: 50 }}>POST /api/v1/entries/:id</h3>

    <Typography variant="body1">
      Updates a single database entry.
    </Typography>

    <pre>
    </pre>

  </>);
}
