import React from 'react';
import axios from 'axios';
import { Table, TableBody, TableRow, TableCell, TableHead, Collapse, IconButton, Box, CircularProgress} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function Row(props) {
    const {e} = props;
    const [open, setOpen] = React.useState(false);

    const renderBeepGreen = new Date() - new Date(e.last_audible_beep_time * 1000) < 10000;

    return (<>
        <TableRow>
            <TableCell>
                {e.peer_states && (
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                )}
            </TableCell>
            <TableCell>
                0x{(e.minor | (e.major << 16)).toString(16).toUpperCase()} ({e.major} {e.minor})
            </TableCell>
            <TableCell>
                {e.bolt_state?.city_name}({e.bolt_state?.city_id})
            </TableCell>
            <TableCell style={{backgroundColor: (new Date() - new Date(e.updated_at * 1000) < 10000) ?  "lightgreen" : undefined}}>
                {new Date(e.updated_at * 1000).toLocaleString()}
            </TableCell>
            <TableCell style={{backgroundColor: (renderBeepGreen ?  "lightgreen" : undefined)}}>
                {e.last_audible_beep_time ? new Date(e.last_audible_beep_time * 1000).toLocaleString() : "-"} ({e.total_audible_beep_cnt})
            </TableCell>
            <TableCell style={{backgroundColor: (renderBeepGreen && e.intensity > 0) ?  "lightgreen" : undefined}}>
                {Number(e.intensity).toFixed(2)}
            </TableCell>
            <TableCell>
                {Number(e.intv).toFixed(2)}
            </TableCell>
            <TableCell>
                {Number(e.dist).toFixed(2)}
            </TableCell>
            {e.peer_states && (<>
              <TableCell>
                  {Object.keys(e.peer_states ?? {}).length}
              </TableCell>
              <TableCell>
                  {e.has_dangling_peer ? "D" : ""}
              </TableCell>
            </>)}
            {(e.flags !== null && e.flags !== undefined) && (
              <TableCell>
                  fl: 0x{e.flags.toString(16)}
              </TableCell>
            )}
        </TableRow>

        {e.peer_states && (
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, backgroundColor: 'lightgray' }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                Peers
                            </Typography> */}
                            <Table size="small">
                                <TableBody>
                                    {Object.values(e.peer_states).map(s => {
                                        return (
                                            <Row e={s} />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>

                        {/* <pre>
                            {JSON.stringify(e.peer_states, null, 4)}
                        </pre> */}
                    </Collapse>
                </TableCell>
            </TableRow>
        )}
    </>);
}

export function ScooterDebugScreen({auth}) {

    const [scooterStates, setScooterStates] = React.useState([]);
    const [debugState, setDebugState] = React.useState({});
    const [finishedInitialLoad, setFinishedInitialLoad] = React.useState(false);
    const timerRef = React.useRef()

    async function reload() {
        try {
            const res = await axios.get(`/api/v1/scooter-engine-debug`, {
                responseType: 'json'
            });
            console.log("get scooter states res:", res);
            const state = res.data;
            setScooterStates(state.scooters);
            delete state['scooters'];

            state["num_connections"] = state["proc_connections"]?.length;
            delete state['proc_connections'];
            setDebugState(state);
        }
        catch(err) {
            console.error("bolt_states.json fetch err", err);
        }
    }

    async function load() {
        await reload();
        setFinishedInitialLoad(true);

        if(timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            reload();
        }, 1000);
    }

    React.useEffect(() => {
        load();
      }, []);

    if(!finishedInitialLoad) {
        return (<CircularProgress />);
    }

    return (<>
        <h1>Scooter Debug</h1>

        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell></TableCell>
                <TableCell>id</TableCell>
                <TableCell>city</TableCell>
                <TableCell>updated</TableCell>
                <TableCell>last audible beep</TableCell>
                <TableCell>volume</TableCell>
                <TableCell>interval</TableCell>
                <TableCell>dist</TableCell>
                <TableCell>peers</TableCell>
                <TableCell>flags</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {Object.values(scooterStates ?? {}).map(e => {
                    return (<>
                        <Row key={e.major + e.minor} e={e} />
                    </>);
                })}
            </TableBody>
        </Table>

        <pre style={{marginTop: 200}}>
            debugState: {JSON.stringify(debugState, null, 4)}
        </pre>

        <pre style={{marginTop: 200}}>
            {JSON.stringify(scooterStates, null, 4)}
        </pre>
    </>
    )
}
